/* Responsive layout for small screens*/
@media (max-width: 960px) {
  .home-row {
    flex-direction: column;
  }

  .home-col11 {
    width: 100%;
  }

  .home-col12 {
    width: 100%;
  }

  .home-col21 {
    width: 100%;
  }

  .home-col22 {
    width: 100%;
  }

  .home-col23 {
    width: 100%;
  }

  .cac-logo-size {
    max-width: 40px;
  }

  .reduce-width {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 310mm;
  }

  .map-container {
    flex-direction: column;
  }

  .map-row-1 {
    width: 100%;
    opacity: 0.8;
  }

  .map-row-2 {
    width: 100%;
  }

  .map-container .p-selectbutton {
    width: 100%;
  }
}
