/* following style will be applied to every element */
/* *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* write your own css after this part */

.background-content {
  background-image: rgb(255, 255, 255);
  /* background-attachment: scroll; */
  padding-top: 15px;
  min-height: calc(85vh - 4px);
  max-width: 100%;
  width: 100%;
}

.bloc-result {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.active {
  background-color: rgb(153, 153, 153);
}

.border {
  border: 4mm groov #126ba8;
}

.text-policy {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
}

.menu-text-policy {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: rgb(0, 0, 0);
}

.footer-text-policy {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: rgba(255, 255, 255);
}

.small-margin {
  margin-right: -1.5rem;
  margin-top: -1em;
}

.no-margin {
  margin-right: 0.2rem !important;
}

.ui.dropdown > .dropdown.icon {
  margin-left: 0.2rem !important;
}

.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
  text-align: center;
}

.inverted-text-color {
  color: rgb(255, 255, 255);
}

html {
  font-size: 1rem;
}

body {
  margin: 0px;
  height: 100%;
  /* padding: 2rem; */
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--surface-ground);
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.map-container .p-selectbutton {
  width: 115%;
}

.map-container .p-selectbutton .p-button {
  width: 33.33%;
}

.map-list-box .p-listbox .p-listbox-list .p-listbox-item {
  font-size: 12px;
}

.map-list-box .p-listbox .p-listbox-header .p-listbox-filter {
  font-size: 12px;
}

.p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
  z-index: 2;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.country-item {
  display: flex;
  align-items: center;
  width: 18px;
}

.country-item img.flag {
  width: 18px;
  margin-right: 0.5rem;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    float: left;
    width: 100%;
    display: flex;
  }

  .col-2 {
    width: 100%;
    float: right;
    display: flex-1;
  }
}

.p-menubar {
  background-color: white !important;
  border: white !important;
}

.p-menu-list {
  background-color: white !important;
}

.p-menubar-root-list {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.p-menuitem {
  background-color: white !important;
}

.p-menu-list:hover {
  background-color: white !important;
}

.p-menuitem-text,
.p-menuitem-icon,
.p-submenu-icon {
  color: black !important;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.p-image {
  color: white !important;
}

.p-dropdown,
.p-dropdown-items,
.p-dropdown-item,
.p-dropdown-items-wrapper,
.p-dropdown-label {
  color: black !important;
}

.pdf-center {
  margin: auto;
  width: 80%;
  border: 3px solid green;
  padding: 10px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
  border: 2px solid black;
}

.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

.news-card {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.privacy-terms {
  text-align: justify;
  margin-left: 2em;
  margin-right: 2em;
}

.pagewrap {
  display: flex;

  /*vertically stack children*/
  flex-direction: column;

  /* expand to take full height of page */
  /* min-height overrides both height & max-height */
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

/**
 * Sticky navigation
 */
.sticky {
  background-color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.reduce-width {
  margin-left: auto;
  margin-right: auto;
  max-width: 380mm;
}

.menu-bar {
  margin-bottom: 1rem;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.p-menubar-end .button-donate {
  background: black;
  border-radius: 1rem;
  border: 2px solid black;
}

.p-menubar-end .button-donate:enabled:hover {
  border-color: #000000;
  background: white;
  color: black;
}

.p-menubar-end .button-donate:enabled:focus {
  border-color: #000000;
  background: white;
  color: black;
}

.section-title {
  margin-bottom: 1em;
}

.footer-bar {
  margin-top: 3rem;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.footer-bar-menu {
  text-decoration: none;
  color: black;
  font-size: small;
}

.h2 {
  margin-top: 1em;
}

.home-row {
  display: flex;
  flex-direction: row;
}

.home-full-col {
  width: 100%;
}

.home-col11 {
  width: 65%;
  margin-right: 1em;
}

.home-col12 {
  width: 35%;
}

.home-col21 {
  width: 35%;
  margin-right: 1em;
}

.home-col22 {
  width: 35%;
  margin-right: 1em;
}

.home-col23 {
  width: 30%;
}

.home-social-networks {
  margin-left: auto;
  margin-right: auto;
  max-width: 150mm;
}

.map-container {
  position: relative;
}

.map-col1 {
  width: 20%;
  margin-right: 1em;
}

.map-col2 {
  width: 80%;
}

.cac-logo-size {
  max-width: 120px;
}

.under-construction {
  height: 50px;
  color: darkred;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 25px;
}

.footer-menu li {
  float: left;
  list-style: none;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.footer-menu li a {
  padding: 0 0.5rem;
  border-right: 1px solid black;
  color: black;
  text-decoration: none;
}

.footer-menu li:last-child a {
  padding: 0 0.5rem;
  border-right: none;
  color: black;
}

.contact-page-link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.top-margin {
  margin-top: 1em;
}

.bottom-margin {
  margin-bottom: 2em;
}

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 600px;
}

#mc-embedded-subscribe-form input[type="checkbox"] {
  display: inline;
  width: auto;
  margin-right: 10px;
}

#mergeRow-gdpr {
  margin-top: 20px;
}

#mergeRow-gdpr fieldset label {
  font-weight: normal;
}

#mc-embedded-subscribe-form .mc_fieldset {
  border: none;
  min-height: 0px;
  padding-bottom: 0px;
}
